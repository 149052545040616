import React, { useEffect, useState, useMemo, useRef } from "react"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  invokeApiCall,
  invokeUpdateApiCall,
  initiateSwalPrompt,
} from "../../api"
import {
  Col,
  Container,
  Card,
  CardBody,
  Row,
  Label,
  Form,
  FormFeedback,
  Input,
  UncontrolledTooltip,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"

import { OrderId } from "../../templates/tableCol"
import TableContainer from "../../components/Common/TableContainer"
import CustomModal from "../../components/Common/CustomModal"
import Pagination from "components/Common/Pagination"
import OrganizationFilter from "./OrganizationFilter"

const MAX_FILE_SIZE = 1024

const OrganizationList = () => {
  //meta title
  document.title = "Regions | regions"
  const dispatch = useDispatch()
  const [record, setRecord] = useState()
  const [records, setRecords] = useState([])
  const [showModal, setshowModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [regions, setRegions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationParams, setpaginationParams] = useState({
    totalRecords: 0,
    currentPage: 1,
  })

  const [orgLogo, setOrgLogo] = useState(null)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: record?.name || "",
      email: record?.email || "",
      service_address: record?.service_address || "",
      web_address: record?.web_address || "",
      registration_number: record?.registration_number || "",
      regions: record?.regions?.map(item => item.id) || [],
      theme: record?.theme || "dark",
      logo: record?.logo || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      email: Yup.string().required("Please enter email"),
      service_address: Yup.string().required("Please enter service address"),
      web_address: Yup.string().required("Please enter web address"),
      registration_number: Yup.string().required(
        "Please enter registration number"
      ),
      regions: Yup.array().notRequired(),
    }),
    onSubmit: async (values) => {
      const newRecord = {
        registration_number: values.registration_number,
        name: values.name,
        email: values.email,
        service_address: values.service_address,
        web_address: values.web_address,
        regions: values.regions,
        theme: values.theme
      };
      if (isEdit) {
        const updateRecord = { id: record.id, ...newRecord };
        if (orgLogo) {
          let formData = new FormData();
          formData.append('file', orgLogo);
          await uploadFile(formData, updateRecord)
        } else {
          updateEdittedRecord(updateRecord, record.id)
        }

      } else {

        if (orgLogo) {
          let formData = new FormData();
          formData.append('file', orgLogo);
          await uploadFile(formData, newRecord)
        } else {
          insertRecord(newRecord);
        }
      }
    },
  })

  const handleDeleteEvent = () => {
    toggle()
  }
  const fetchRecords = params => {
    invokeApiCall("FETCH_ORGANIZATIONS", params).then(res => {
      const results = res.data
      setpaginationParams({
        totalRecords: results?.count,
        filterParams: params,
      })
      setCurrentPage(params?.page || currentPage)
      setRecords(results.results)
    })
  }

  const createRecord = () => {
    // fetch regions
    if (!regions.length) {
      invokeApiCall("FETCH_ALL_REGIONS", {}).then(res => {
        const results = res.data
        setRegions(results)
      })
    }

    setIsEdit(false)
    setshowModal(true)
  }

  const editRecord = id => {
    const params = id
    // fetch details & popup
    invokeApiCall("FETCH_ORGANIZATION_DETAIL", params).then(res => {
      const results = res.data
      handleUserClick(results)
    })
  }
  const updateEdittedRecord = (payload, pk) => {
    invokeUpdateApiCall("UPDATE_ORGANIZATION", payload, pk).then(res => {
      const results = res.data
      setshowModal(false)
      fetchRecords()
    })
  }

  const insertRecord = payload => {
    invokeApiCall("CREATE_ORGANIZATION", payload).then(res => {
      const results = res.data
      setshowModal(false)
      fetchRecords()
    })
  }

  const uploadFile = async (form, data) => {
    await invokeApiCall("DOCUMENT_UPLOAD", form).then((res) => {
      if (res && res?.uploaded_files?.length > 0) {
        validation.setFieldValue('logo', String(res?.uploaded_files[0]))
          .then(() => {
            if (isEdit) {
              updateEdittedRecord({ ...data, logo: res?.uploaded_files[0] }, data?.id);
            } else {
              insertRecord({ ...data, logo: res?.uploaded_files[0] });
            }

          })
      }
    })
  }

  const sideBarTheme = {
    light: "#FFFFFF",
    colored: "#556EE6",
    dark: "#2A3042",
    winter: "#487BD8",
    ladylip: "#706ED1",
    plumplate: "#D8A969",
    strongbliss: "#F47468",
    greatwhale: "#4692EA",
  }

  const removeRecord = payload => {
    initiateSwalPrompt().then(value => {
      if (value) {
        invokeUpdateApiCall("DELETE_ORGANIZATIONS", {}, payload).then(res => {
          fetchRecords()
        })
      }
    })
  }

  const handleUserClick = arg => {
    const details = arg

    if (!regions.length) {
      invokeApiCall("FETCH_REGIONS", {}).then(res => {
        const results = res.data
        setRegions(results.results)
      })
    }

    validation.setValues({
      id: details.id,
      name: details.name,
      registration_number: details.registration_number,
      email: details.email,
      service_address: details.service_address,
      web_address: details.web_address,
      theme: details.theme,
      regions: details.regions?.map(item => item.id),
      logo: details?.logo,
    }).then(() => {
      setRecord(details)
      setIsEdit(true)
      setshowModal(true)
    })
  }
  useEffect(() => {
    fetchRecords()
  }, [])

  const fetchPaginated = page => {
    const paginationParams = {
      page: page,
    }
    fetchRecords(paginationParams)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Registration Number",
        accessor: "registration_number",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Web Address",
        accessor: "web_address",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Service Address",
        accessor: "service_address",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-3">
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  editRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Button>
              <Button
                type="button"
                color="danger"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  removeRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Remove
                </UncontrolledTooltip>
              </Button>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <CustomModal
        show={showModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => {
          validation.handleReset()
          setshowModal(false)
        }}
        addSubmitButton
        onActionSubmit={() => {
          validation.handleSubmit()
        }}
        size="lg"
        title="Organization details"
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          className="text-start"
        >
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label className="form-label">Organization Name</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=""
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder=""
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label className="form-label">Registration Number</Label>
                <Input
                  name="registration_number"
                  type="text"
                  placeholder=""
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.registration_number || ""}
                  invalid={
                    validation.touched.registration_number &&
                      validation.errors.registration_number
                      ? true
                      : false
                  }
                />
                {validation.touched.registration_number &&
                  validation.errors.registration_number ? (
                  <FormFeedback type="invalid">
                    {validation.errors.registration_number}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-2">
                <Label className="form-label">Physical Address</Label>
                <Input
                  name="service_address"
                  type="text"
                  placeholder=""
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.service_address || ""}
                  invalid={
                    validation.touched.service_address &&
                      validation.errors.service_address
                      ? true
                      : false
                  }
                />
                {validation.touched.service_address &&
                  validation.errors.service_address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.service_address}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Web Address</Label>
                <Input
                  name="web_address"
                  type="text"
                  placeholder=""
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.web_address || ""}
                  invalid={
                    validation.touched.web_address &&
                      validation.errors.web_address
                      ? true
                      : false
                  }
                />
                {validation.touched.web_address &&
                  validation.errors.web_address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.web_address}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Region</Label>
                <Input
                  multiple
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Enter the region"
                  name="regions"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.regions || ""}
                  invalid={
                    validation.touched.regions && validation.errors.regions
                      ? true
                      : false
                  }
                >
                  <option value={""} disabled hidden></option>
                  {regions?.length &&
                    regions?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Input>
                {validation.touched.regions && validation.errors.regions ? (
                  <FormFeedback type="invalid">
                    {validation.errors.region}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="mb-2">
                <Label
                  className="form-label"
                  style={{
                    display: "flex",
                    gap: 4,
                  }}
                >
                  Theme
                  <p
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: sideBarTheme[validation.values.theme],
                    }}
                  ></p>
                </Label>
                <Input
                  name="theme"
                  type="select"
                  defaultValue="dark"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.theme || "dark"}
                  invalid={
                    validation.touched.theme && validation.errors.theme
                      ? true
                      : false
                  }
                >
                  <option value="dark">Dark</option>
                  <option value="light">Light</option>
                  <option value="winter">Winter</option>
                  <option value="ladylip">Ladylip</option>
                  <option value="strongbliss">Strongbliss</option>
                  <option value="plumplate">Plumplate</option>
                  <option value="greatwhale">Greatwhale</option>
                </Input>
                {validation.touched.theme && validation.errors.theme ? (
                  <FormFeedback type="invalid">
                    {validation.errors.theme}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-2">
                <Label
                  className="form-label"
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                  }}
                >
                  Logo
                </Label>
                <Input
                  name="logo"
                  type="file"
                  onChange={async e => {
                    if (e.target.files.length > 0) {
                      const file = e.target.files[0]
                      const fileSizeKiloBytes = file.size / 1024
                      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                        validation.setFieldError("logo", "File size too big!")
                        return
                      } else {
                        validation.handleChange(e)
                        setOrgLogo(file)
                      }
                    }
                  }}
                  onBlur={validation.handleBlur}
                  // value={validation.values.logo}
                  accept=".png,.jpg,.jpeg,.avif,.svg,.webP"
                  invalid={
                    validation.touched.logo && validation.errors.logo
                      ? true
                      : false
                  }
                />
                {validation.touched.logo && validation.errors.logo && (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                )}
                {(orgLogo || validation.values.logo) && (
                  <img
                    src={orgLogo ? URL.createObjectURL(orgLogo) : validation.values.logo}
                    alt=""
                    className="mt-2 w-100 rounded"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "60px",
                      borderRadius: "5px",
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </CustomModal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="organizations" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={createRecord}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Add Organization
                    </Button>
                  </div>
                  <div className="mb-4 h4 card-title">All records</div>

                  <OrganizationFilter fetchRecords={fetchRecords} />
                  <TableContainer
                    columns={columns}
                    data={records}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={10}
                  />
                  <Pagination
                    totalRows={paginationParams.totalRecords}
                    pageChangeHandler={fetchPaginated}
                    rowsPerPage={10}
                    currentPage={currentPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(OrganizationList)
