import { checkPermissions } from "permissions/ability"

export const getMenuItems = () => {
  const sideMenuItems = [
    {
      id: 1,
      title: true,
      name: "Main Menu",
      permissions: true,
    },
    {
      id: 2,
      name: "Home",
      route: "/dashboard",
      icon: "bx bx-home",
      permissions: true,
    },
    {
      id: 3,
      name: "Analytics",
      route: "/",
      icon: "bx bx-pie-chart-alt-2",
      roles: [],
      permissions: checkPermissions(["can_view_reports"]),
      children: [
        {
          id: 1,
          name: "Regions",
          route: "/region-analytics",
          icon: "bx bx-map",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },

        {
          id: 1,
          name: "Organization",
          route: "/organization-analytics",
          icon: "bx bx-buildings",
          roles: ["member"],
          permissions: checkPermissions(["can_view_organization_analytics"]),
        },
        {
          id: 1,
          name: "Status",
          route: "/status-analytics",
          icon: "bx bx-station",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },

        {
          id: 1,
          name: "Category",
          route: "/category-analytics",
          icon: "bx bx-book-content",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },
        {
          id: 1,
          name: "Question",
          route: "/question-analytics",
          icon: "bx bx-book-content",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },
      ],
    },
    {
      id: 3,
      name: "Reports",
      route: "/incoming-reports",
      icon: "bx bx-briefcase-alt-2",
      roles: [],
      permissions: checkPermissions(["can_view_reports"]),
      children: [
        {
          id: 1,
          name: "New reports",
          route: "/new-reports",
          icon: "bx bx-comment-add",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },
        {
          id: 2,
          name: "Referred reports",
          route: "/referred-reports",
          icon: "bx bx-fast-forward-circle",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },

        {
          id: 3,
          name: "Solved reports",
          route: "/solved-reports",
          icon: "bx bx-check-circle",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },

        {
          id: 4,
          name: "Dismissed reports",
          route: "/dismissed-reports",
          icon: "bx bx-no-entry",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },
        {
          id: 5,
          name: "Banned reports",
          route: "/banned-reports",
          icon: "bx bx-block",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },
        {
          id: 6,
          name: "Archived reports",
          route: "/archived-reports",
          icon: "bx bx-archive",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },
        {
          id: 6,
          name: "Add Report",
          route: "/add-report",
          icon: "bx bx-plus-circle",
          roles: ["member"],
          permissions: checkPermissions(["can_create_report"]),
        },
        {
          id: 6,
          name: "Search Report",
          route: "/search-report",
          icon: "bx bx-search",
          roles: ["member"],
          permissions: ["can_view_incoming_reports"],
        },
      ],
    },

    {
      id: 4,
      name: "Configuration",
      route: "/events",
      icon: "bx bx-cog",
      roles: [],
      permissions: checkPermissions(["can_view_configurations"]),
      children: [
        {
          id: 1,
          name: "Questions",
          route: "/questions",
          icon: "bx bx-question-mark",
          roles: ["member"],
          permissions: checkPermissions(["can_view_questions"]),
        },
        {
          id: 2,
          name: "Regions",
          route: "/regions",
          icon: "bx bx-map",
          roles: ["member"],
          permissions: checkPermissions(["can_view_regions"]),
        },
        // {
        //   id: 3,
        //   name: "Supported Languages",
        //   route: "/languages",
        //   icon: "bx bx-chevron-right",
        //   roles: ["admin"],
        //   permissions: checkPermissions(["can_view_languages"]),
        // },
        {
          id: 4,
          name: "Blacklisted words",
          route: "/black-listed-words",
          icon: "bx bx-block",
          roles: ["admin"],
          permissions: checkPermissions(["can_view_blacklisted_words"]),
        },
        {
          id: 5,
          name: "Report types",
          route: "/report-types",
          icon: "bx bx-wrench",
          roles: ["member"],
          permissions: ["can_view_report_types"],
        },
        {
          id: 5,
          name: "Sectors",
          route: "/sectors",
          icon: "bx bx-layout",
          roles: ["member"],
          permissions: checkPermissions(["can_view_regions"]),
        },
      ],
    },
    {
      id: 5,
      name: "Subscribers",
      route: "/subscribers",
      icon: "bx bx-user-check",
      roles: [],
      permissions: checkPermissions(["can_view_subscribers"]),
      children: [
        {
          id: 1,
          name: "Subscribers",
          route: "/subscribers",
          icon: "bx bx-street-view",
          roles: ["member"],
          permissions: ["can_view_subscribers"],
        },
      ],
    },

    {
      id: 6,
      name: "User management",
      route: "/abstracts",
      icon: "bx bx-user-pin",
      roles: [],
      permissions: checkPermissions(["can_view_usermanagement"]),
      children: [
        {
          id: 2,
          name: "Organizations",
          route: "/organizations",
          icon: "bx bx-buildings",
          roles: ["member"],
          permissions: checkPermissions(["can_view_organizations"]),
        },
        {
          id: 2,
          name: "Users",
          route: "/users",
          icon: "bx bx-street-view",
          roles: ["member"],
          permissions: checkPermissions(["can_view_users"]),
        },
        {
          id: 2,
          name: "Api User",
          route: "/developers",
          icon: "bx bx-street-view",
          roles: ["member"],
          permissions: checkPermissions(["can_view_users"]),
        },
      ],
    },

    {
      id: 6,
      name: "Bulk SMS",
      route: "/sms",
      icon: "bx bx-message-dots",
      roles: [],
      permissions: checkPermissions(["can_view_bulk_sms"]),
      children: [
        {
          id: 2,
          name: "Send Sms",
          route: "/bulk-sms",
          icon: "bx bx-message-alt-add",
          roles: ["member"],
          permissions: checkPermissions(["can_view_bulk_sms"]),
        },
      ],
    },

    {
      id: 7,
      name: "Profile",
      route: "/profile",
      icon: "bx bx-user",
      roles: ["admin", "member"],
      permissions: true,
    },
  ]

  return sideMenuItems
}
